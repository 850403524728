<template>
  <div>
    <div class="gy_departDetail" v-if="!showAssetsGl">
      <van-nav-bar
        title="资产详情"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        fixed
        class="aaa"
      >
        <template #right v-if="!isStatus">
          <van-icon name="delete-o" />
        </template>
      </van-nav-bar>
      <!-- <van-loading type="spinner" /> -->
      <div class="content_people">
        <div class="form_block">
          <van-field
            v-model="op_name"
            name="盘点人"
            label="盘点人"
            placeholder="输入盘点人"
            input-align="right"
            :rules="[{ required: true, message: '请填写盘点人' }]"
            :disabled="isDisabled"
          />
          <div class="custom-remark" :key="index" v-if="remark_list.length > 0">
            <div class="row1">
              <span>客户备注:</span>
            </div>
            <div class="row2" style="margin: 10px 0; color: #F59F03">{{remark_list[0].content}}</div>
            <div class="row3" style="color: #c1bcbc;">
              <span>{{remark_list[0].createtime}}</span>
              <span style="margin-left: 40px">{{remark_list[0].user}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="not_click" v-if="isStatus || isEdit" @click="edit_click"></div>
        <div class="form_block">
          <van-form>
            <van-field
              v-model="check_no"
              name="盘点编号"
              label="盘点编号"
              placeholder="输入盘点编号"
              input-align="right"
              :rules="[{ required: true, message: '请填写盘点编号' }]"
              :disabled="isDisabled"
            />
            <van-field
              v-if="enter_no"
              v-model="enter_no"
              name="录入资产编号"
              label="录入资产编号"
              placeholder="输入录入资产编号"
              input-align="right"
              :rules="[{ required: true, message: '请填写录入资产编号' }]"
              disabled
            />
            <van-cell is-link title="资产类别" @click="isDisabled ? '' : showType = true" :value="type_name" :class="{'disabled': isDisabled}" />
            <van-popup v-model="showType" round position="bottom">
              <van-picker
                show-toolbar
                :lazy-render='false'
                :columns="typeList"
                @cancel="showType = false"
                @confirm="onConfirm_type"
                ref="pickerType"
              />
            </van-popup>
            <van-cell
              title="资产名称" 
              @click="systemName_click" 
              :value="name"
              is-link
            />
            <div v-if="showName">
              <SystemName :showName="showName" :department="department" :department_name="department_name" @close_systemName="close_systemName" />
            </div>
            <van-field
              v-model="brand"
              name="资产品牌"
              label="资产品牌"
              placeholder="输入品牌名称"
              input-align="right"
              :disabled="isDisabled"
            />
              <!-- :rules="[{ required: true, message: '请填写资产品牌' }]" -->
            <van-field
              v-model="model"
              name="型号规格"
              label="型号规格"
              placeholder="输入型号规格"
              input-align="right"
              :disabled="isDisabled"
              @input="model_change"
            />
              <!-- :rules="[{ required: true, message: '请填写型号规格' }]" -->
            <van-cell is-link title="年份" @click="isDisabled ? '' : showYear = true" :value="year" :class="{'disabled': isDisabled}" />
            <van-popup v-model="showYear" round position="bottom">
              <van-picker
                show-toolbar
                :columns="yearList"
                @cancel="showYear = false"
                @confirm="onConfirm_year"
              />
            </van-popup>
            <van-cell is-link title="实盘使用部门" @click="showDep = true" :value="department_name" />
            <van-popup v-model="showDep" round position="bottom">
              <van-picker
                show-toolbar
                :columns="depList"
                @cancel="showDep = false"
                @confirm="onConfirm_dep"
                @change="department_change"
              />
            </van-popup>
            <van-field
              v-model="room_no"
              name="部门房间号"
              label="部门房间号"
              placeholder="请输入房间号"
              input-align="right"
              :rules="[{ required: true, message: '请输入房间号' }]"
            />
            <van-field
              v-model="user"
              name="实盘使用人(选填)"
              label="实盘使用人(选填)"
              placeholder="请输入资产使用人"
              input-align="right"
            />
          </van-form>
          <van-field name="radio" label="是否闲置" input-align="right">
            <template #input>
              <van-radio-group v-model="unused" direction="horizontal">
                <van-radio name="1">是</van-radio>
                <van-radio name="0">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field name="radio" label="是否补签" input-align="right">
            <template #input>
              <van-radio-group v-model="is_repair" direction="horizontal">
                <van-radio name="1">是</van-radio>
                <van-radio name="0">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <div style="padding: 17px 0 0 16px">盘点照片</div>
          <van-field name="uploader" label="">
            <template #input>
              <van-uploader v-model="fileList" multiple :max-count="500" :after-read="afterRead" :before-delete="deleteImg" />
            </template>
          </van-field>
          <van-field
            v-model="remark"
            rows="3"
            autosize
            label="备注（选填）"
            type="textarea"
            maxlength="50"
            input-align="right"
            placeholder="填写备注信息"
          />
        </div>
        <div class="info">
          <div class="row first_row">
            <span>盘点关联</span>
            <van-button round block type="info" class="gl_btn" @click="gl_click"
              >{{glBtn_name}}</van-button
            >
          </div>
          <div class="row">
            <span>关联级别</span>
            <span class="text"> {{guanlian.linked_name}} </span>
          </div>
          <div class="row">
            <span>资产编码</span>
            <span class="text"> {{guanlian.item_no}} </span>
          </div>
          <div class="row">
            <span>资产名称</span>
            <span class="text"> {{guanlian.item_name}} </span>
          </div>
          <div class="row">
            <span>类别</span>
            <span class="text" v-if="guanlian.main_type"> {{ guanlian.main_type }}-{{ guanlian.sub_type }} </span>
          </div>
          <div class="row">
            <span>品牌</span>
            <span class="text"> {{guanlian.brand}} </span>
          </div>
          <div class="row">
            <span>规格型号</span>
            <span class="text"> {{guanlian.model}} </span>
          </div>
          <div class="row">
            <span>使用部门</span>
            <span class="text"> {{
              guanlian.use_dep
                ? typeof guanlian.use_dep === "string"
                  ? guanlian.use_dep
                  : guanlian.use_dep[0]
                : ""
            }} </span>
          </div>
          <div class="row">
            <span>管理部门</span>
            <span class="text"> {{
              guanlian.manage_dep
                ? typeof guanlian.manage_dep === "string"
                  ? guanlian.manage_dep
                  : guanlian.manage_dep[0]
                : ""
            }} </span>
          </div>
          <div class="row">
            <span>单价金额</span>
            <span class="text"> {{
              guanlian.price
            }} </span>
          </div>
          <div class="row">
            <span>使用人</span>
            <span class="text"> {{guanlian.user}} </span>
          </div>
          <div class="row">
            <span>存放地点</span>
            <span class="text"> {{guanlian.stock_addr}} </span>
          </div>
          <van-field
            v-model="guanlian_remark"
            class="remarks"
            name=""
            label=""
            placeholder="该产品备注信息"
            :disabled="true"
          />
          <!-- <div class="row">
            <span>单价金额</span>
            <span class="text"> {{
              guanlian.remark
            }} </span>
          </div> -->
        </div>
      </div>
      <!-- <div class="btn_block" v-if="!isStatus && !isAA"> -->
      <div>
        <div class="btn_block" v-if="$route.query.isfromPankui">
          <div style="display: flex" v-if="$route.query.str === 'pk'">
            <van-button round block :loading="loading" type="info" @click="changeToPankui" class="btn_red">转入盘亏</van-button>
            <van-button round block :loading="loading" type="info" @click="submit_form" :class="{'btn_true': isTrue}" style="width: 43%">{{btn_text}}</van-button>
          </div>
          <div class="btn_block" v-else>
            <van-button round block :loading="loading" type="info" @click="submit_form" :class="{'btn_true': isTrue}">{{btn_text}}</van-button>
            <!-- <van-button round block :loading="loading" type="info" @click="save_copy" class="btn_red">保存并复制</van-button> -->
          </div>
        </div>
        <div class="btn_block" v-if="!isStatus && !$route.query.isfromPankui" style="display: flex">
          <van-button round block :loading="loading" type="info" @click="submit_form" :class="{'btn_true': isTrue}" style="width: 43%">{{btn_text}}</van-button>
          <van-button round block :loading="loading" type="info" @click="save_copy" class="btn_red">保存并复制</van-button>
        </div>
      </div>
    </div>
    <div v-else-if="showAList">
      <AList @showAddPage="showAddPage" @showAddPage2="showAddPage2" @showAddPage_remove="showAddPage_remove" :brand="brand" :name="name" :check_no="check_no" :glBtn_name="glBtn_name" :AAsearch_id="AAsearch_id" :dep="department" :AListData="AListData"></AList>
    </div>
    <div v-else>
      <AssetsGl @showAddPage="showAddPage" @showAddPage2="showAddPage2" @showAddPage_remove="showAddPage_remove" :brand="brand" :name="name" :check_no="check_no" :glBtn_name="glBtn_name" :AAsearch_id="AAsearch_id" :dep="department"></AssetsGl>
    </div>

  </div>
</template>
<script>
import AssetsGl from './components/assetsGl.vue'
import AList from './components/aList.vue'
import SystemName from './components/systemName.vue'
import addWaterMask from '../../utils/addWaterMask.js';
export default {
  data() {
    return {
      showType: false,
      showYear: false,
      showDep: false,
      typeList: [],
      depList: [],
      depObj: [],
      yearList: [],
      actions1: [{ name: "A+" }, { name: "A" }, { name: "B" }],
      check_no: '',
      op_name: '',
      name: '',
      enter_no: '',
      type_name: '',
      type_main: null,
      type_sub: null,
      brand: '',
      model: '',
      year: '',
      department: '',
      department_name: '',
      room_no: '',
      user: '',
      unused: null,
      is_repair: null,
      images: [],
      fileList: [],
      fileList_url: [],
      fileList_obj: [],
      remark: '',
      linked_id: '',
      showAssetsGl: false,
      showAList: false,
      AListData: [],
      isStatus: false, // 是否已验收
      glBtn_name: '关联',
      isEdit: 0,
      isAA: false,
      AAsearch_id: '',
      isDisabled: false,
      loading: false,
      showName: false,
      is_replay: null,
      replay_status: null,
      // 关联块字段
      guanlian: {
        // linked_name: '',
        // item_no: '',
      },
      guanlian_remark: '',
      btn_text: '保存',
      remark_list: []
    };
  },

  computed: {
    isTrue() {
      return (
        this.check_no 
        && this.name 
        // && (this.type_main || this.type_sub)
        && this.year
        && this.brand
        && this.model
        && this.room_no 
        && (this.unused !== null)
        && (this.fileList.length > 0)
      )
    }
  },

  components: {
    AssetsGl,
    AList,
    SystemName
  },

  mounted() {
    this.getFL()
    this.getDeplist()
    this.getYearList()
    setTimeout(() => {
      if (this.$route.query.id) {
        this.getEditData()
        if (this.$route.query.reply) {
          this.isDisabled = true
        }
      } else {
        this.isStatus =  false
      }
    }, 500)
    if (this.common.formatQueryDate(this.$route.query.status)) {
      this.glBtn_name = '查看'
    }
    
  },

  methods: {
    getFL() {
      this.$http({
        url: "/project/type",
        method: "post",
      }).then((res) => {
        if (res.code === 1) {
          let list = res.data
          this.typeList = this.recursionData(list)
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    recursionData(list) {
      let arr = list.map(e =>  {
        e.text = e.name
        e.value = e.id
        if (e.children && e.children.length > 0) {
          this.recursionData(e.children)
        } else if (e.children && e.children.length == 0) {
          e.children = [
            {text: '', id: ''}
          ]
        }
        return e
      })
      return arr
    },
    onConfirm_type() {
      let ref = this.$refs['pickerType'].getValues()
      console.log(ref)
      this.type_main = ref[0].id
      this.type_sub = ref[1].id
      this.type_name = ref[0].name + (ref[1].name ? ('-' + ref[1].name) : '')
      this.showType = false
    },

    getDeplist() {
      this.$http({
        url: "/project/deplist",
        method: "get",
      }).then((res) => {
        if (res.code === 1) {
          this.depObj = res.data
          this.depList = this.depObj.map(e => e.name)
          if (!this.$route.query.id) {
            this.department = this.$route.query.departmentId
            console.log('this.department', this.department, this.depObj.filter(e => e.id == this.department))
            this.department_name = this.depObj.filter(e => e.id == this.department)[0].name
          }
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    onConfirm_dep(value) {
      this.department_name = this.depObj.find(e => e.name === value).name
      this.department = this.depObj.find(e => e.name === value).id
      this.showDep = false
    },

    getYearList() {
      var myDate = new Date();
      var startYear = myDate.getFullYear() - 25;//起始年份
      var endYear = myDate.getFullYear();//结束年份
      for (let i = startYear; i <= endYear; i++) {
        this.yearList.push(i)
      }
      this.yearList = this.yearList.reverse()
    },
    onConfirm_year(value) {
      this.year = value
      this.showYear = false
    },

    onClickLeft() {
      this.$dialog.confirm({
        title: '提示',
        message: '该页面为表单页，您确认离开此页吗？',
      })
        .then(() => {
          if (this.$route.query.fromSearch) {
            // this.$router.push({ name: "searchResult", query: {kw: this.$route.query.kw} });
            this.$router.push({ name: "checkProject" });
          } else if (this.$route.query.isfromPankui) {
            this.$router.push({ name: "stayAssets", query: {id: this.$route.query.departmentId, name: this.$route.query.name} });
          } else {
            this.$router.push({ name: "departDetail", query: {department: this.$route.query.departmentId, name: this.$route.query.name, status: this.$route.query.status} });
          }
        })
        .catch(() => {
          
        });
    },

    onClickRight() {
      this.$dialog
        .confirm({
          title: "温馨提示",
          message: "删除后，该条录入资产数据不再保存， 是否确定删除？",
          confirmButtonText: "确定",
        })
        .then(() => {
          this.$http({
            url: "/inventory/del",
            method: "post",
            data: {id: this.$route.query.id}
          }).then((res) => {
            if (res.code === 1) {
              sessionStorage.setItem('refresh', true)
              this.$router.push({ name: "departDetail", query: {department: this.$route.query.departmentId, name: this.$route.query.name, status: this.$route.query.status} });
            } else {
              this.$toast.fail(res.msg);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },

    gl_click() {
      if (this.glBtn_name === '查看') {
        this.AAsearch_id = this.linked_id
        this.showAssetsGl = true
        this.showAList = false
        return
      }

      if (this.glBtn_name === '解除关联') {
        this.$dialog.confirm({
          title: "温馨提示",
          message: "是否解除该资产关联关系",
          confirmButtonText: "确定解除",
        })
        .then(() => {
          this.$http({
            url: "/inventory/remove",
            method: "post",
            data: {id: this.linked_id}
          }).then((res) => {
            if (res.code === 1) {
              this.$toast.success('解绑成功！');
              // location.reload()
              this.glBtn_name = '关联'
              this.linked_id = ''
              this.guanlian = {}
            } else {
              this.$toast.fail(res.msg);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
      } else {
        let params = {
          department: this.department,
          model: this.model,
          name: this.name
        }
        this.$http({
          url: "/inventory/match",
          method: "post",
          data: params
        }).then((res) => {
          if (res.code === 1) {
            if (res.data) {
              if (res.data.length === 1) {
                let data = res.data[0]
                this.AAsearch_id = data.id
                this.linked_id = data.id
                this.guanlian.linked_name ='A+级关联'
                this.glBtn_name = '查看'
                this.guanlian.item_no = data.item_no,
                this.replace = data.replace
                this.guanlian.user = data.user
                this.guanlian.stock_addr = data.stock_addr
                this.guanlian.item_name = data.item_name
                this.guanlian.brand = data.brand
                this.guanlian.main_type = data.main_type
                this.guanlian.sub_type = data.sub_type
                this.guanlian.model = data.model
                this.guanlian.price = data.price
                this.guanlian.use_dep = typeof data.use_dep === "string"
                  ? data.use_dep
                  : data.use_dep[0]
                this.guanlian.manage_dep = data.manage_dep
                ? typeof data.manage_dep === "string"
                  ? data.manage_dep
                  : data.manage_dep[0]
                : ""
              } else if (res.data.length > 1) {
                this.AListData = res.data
                this.showAssetsGl = true
                this.showAList = true
              }
            } else {
              this.glBtn_name = '关联'
              this.showAssetsGl = true
              this.showAList = false
            }
          } else {
            this.$toast.fail(res.msg);
          }
        });
      }
    },
    showAddPage(item) {
      // console.log('888')
      this.showAssetsGl = false
      this.showAList = false
      this.linked_id = item.id
      this.replace = item.replace ? item.replace : ''
      this.guanlian = {
        linked_name: item.linked_name,
        item_no: item.item_no,
        item_name: item.item_name ? item.item_name : item.name,
        main_type: item.type_main ? item.type_main : item.main_type,
        sub_type: item.type_sub ? item.type_sub : item.sub_type,
        brand: item.brand,
        model: item.model,
        use_dep: item.department ? item.department : item.use_dep,
        manage_dep: item.manage_dep ? item.manage_dep : item.manage_dep,
        price: item.price,
        remark: item.remark,
        user: item.user,
        stock_addr: item.stock_addr
      }
      this.guanlian_remark = item.remark
    },
    showAddPage2() {
      // console.log('99', this.guanlian.linked_name)
      this.showAssetsGl = false
      this.showAList = false
    },
    showAddPage_remove() {
      this.showAssetsGl = false
      this.showAList = false
      this.linked_id = ''
      this.guanlian = {}
    },

    deleteImg(file) {
      if (file.content) {
        this.fileList_obj = this.fileList_obj.filter(e => e.name !== file.file.name)
      } else {
        this.fileList_obj = this.fileList_obj.filter(e => !file.url.includes(e.name))
      }
      return true
    },

    async transformFile(file) {
        //promise执行多步操作非常好用，这个过程是有一定的顺序的，你必须保证上一步完成，才能顺利进行下一步。
        //有两个参数 resolve同意的意思  reject拒绝的意思
      return new Promise(resolve => {
        const reader = new FileReader();//创建读取文件对象
        reader.readAsDataURL(file);//调用方法转base64
        reader.onload = () => { //文件读取成功完成的触发
          // const canvas = document.createElement('canvas'); //新建canvas 画布
          const img = document.createElement('img'); //新建一个img标签
          img.src = reader.result; //读取结果 下载图片
      
          img.onload = () => { //绑定事件 下载完成
            // const ctx = canvas.getContext('2d');//获取画笔对象
            // let width = img.width; //图片的宽高
            // let height = img.height;
            // canvas.width = width;//画布的宽高
            // canvas.height = height;
            // ctx.drawImage(img, 0, 0, width, height);//绘制图像 图片大小没变
            // ctx.fillStyle = '#000';// 设置填充字号和字体，样式
            // ctx.textBaseline = 'middle';
            // // 设置右对齐
            // ctx.textAlign = 'right'
            // ctx.rotate(0*Math.PI/180);//旋转画笔 角度
            // ctx.globalAlpha = 0.8; //透明度
            const text  = new Date().toLocaleDateString().split('/').join('.')
            // if(2000000 < width * height && width * height < 4000000){
            //   ctx.font="100px Arial";
            // }else if(width * height > 4000000){
            //   ctx.font="120px Arial";  // 设置文本大小和字体
            //   // ctx.fillText(text, width/1.5, height/8); //绘制填充文本(str,x,y) 填充字体的 坐标
            //   ctx.fillText(text, width-20, height-30);// 在指定位置绘制文字，这里指定距离右下角20坐标的地方
            // }else{
            //   ctx.font="80px Arial";
            // }
            // // ctx.fillText(text, width/8, height/8);
            // ctx.fillText(text, width-20, height-30);// 在指定位置绘制文字，这里指定距离右下角20坐标的地方
            // /* canvas.toBlob(resolve); */
            // //压缩图片质量 范围 0~1
            const dataUrl = addWaterMask(img, 30,text,'#00000060')
              console.log('dataUrl',dataUrl)
            // let  dataUrl = canvas.toDataURL("image/jpeg", 0.5) //输出压缩后的base64
            //执行下一步操作  将裁剪base64的图片转换为file文件
            resolve(this.dataURLtoFile(dataUrl, file.name))
          };
        };
      });
    },

    async afterRead(file) {
      // console.log('66', file)
      let files = file
      if (!(file instanceof Array)) {
        files = [files]
      }
      this.loading = true
      for(let i = 0; i < files.length; i++) {
        files.status = 'uploading'
        file.message = '上传中...'
        let formData = new FormData()
        console.log('88', this.fileList)
        let canvasBase64File = await this.transformFile(files[i].file)
        // let fileData = this.dataURLtoFile(canvasBase64File, files[i].file.name + '.png')
        // let fileData = files.file
        let newImg = await this.compressorImage.compressorImage(canvasBase64File, 'file', 0.1)
        formData.append("file", newImg)
        formData.append("category", 'check')
        this.$http({
          url: "/common/upload",
          method: "post",
          data: formData
        }).then(res => {
          if (res.code === 1) {
            this.fileList_obj.push({
              url: res.data.url,
              name: files[i].file.name
            })
            this.fileList[this.fileList.length -1] = {
              // url: 'http://guyou.bjxiaomai.cn' + res.data.url,
              url: 'https://image.bjguyou.com' + res.data.url,
              name: files[i].file.name
            }
            this.fileList = [...this.fileList]
            // console.log(this.fileList ,'121')
            files.status = 'done'
            // console.log('this.fileList_obj', this.fileList_obj)
            if (i == (files.length -1)) {
              console.log('结束上传')
              this.loading = false
            }
          } else {
            this.$toast.fail('图片上传失败, 请重新保存！');
            return
          }
        }).catch(e => {
          this.$toast.fail(e+'图片上传失败, 请重新保存！');
          return
        })
      }
      
    },

    dataURLtoFile(dataurl,filename = '') {
      var arr = dataurl.split(','),
        // mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      // if (mime) {
      //   var mime_arr = mime.split('/')
      //   if (filename == '') {
      //     filename = randomStr(6) + '.' + mime_arr[1]
      //   }
      // }
      return new File([u8arr], filename, {type: 'image/png'})
    },

    submit_form() {
      if (!this.isTrue) {
        return
      }
      this.fileList_url = this.fileList_obj.map(e => e.url)
      if (this.$route.query.id && (this.replay_status == 0)) {
        let len = this.fileList_url.filter(e => this.oldfileList_url.includes(e))
        if (len.length === this.fileList_url.length) {
          this.$toast.fail('请上传复盘照片');
          console.log('aaaaa', this.oldfileList_url, this.fileList_url, len)
          return
        }
      }
      let params = {
        department: this.department,
        name: this.name,
        op_name: this.op_name,
        check_no: this.check_no,
        type_main: this.type_main,
        type_sub	: this.type_sub,
        brand: this.brand,
        model: this.model,
        year: this.year,
        room_no: this.room_no,
        user: this.user,
        unused: this.unused,
        is_repair: this.is_repair,
        remark: this.remark,
        images: this.fileList_url,
        linked_id: this.linked_id ,
        status: this.guanlian.linked_name === 'A+级关联' ? 1 : this.guanlian.linked_name === 'A级关联' ? 2 : this.guanlian.linked_name === 'B级关联' ? 3 : '',
        item_no: this.guanlian.item_no,
        enter_no: this.enter_no,
        replace: this.replace
      }
      console.log('params', params)
      if (this.$route.query.id) {
        this.$http({
          url: "/inventory/edit",
          method: "post",
          data: {...params, id: this.$route.query.id}
        }).then((res) => {
          this.loading = false
          if (res.code === 1) {
            if (this.$route.query.fromSearch) {
              // this.$router.push({ name: "searchResult", query: {kw: this.$route.query.kw} });
              this.$router.push({ name: "checkProject" });
            } else {
              // sessionStorage.setItem('refresh', true)
              this.$router.push({ name: "departDetail", query: {department: this.$route.query.departmentId || this.$route.query.id, name: this.$route.query.name, status: this.$route.query.status} });
            }
          } else {
            this.$toast.fail(res.msg);
          }
        }).catch(err => {
          console.log(err)
          this.loading = false
          this.$toast.fail('保存失败！');
        })
      } else {
        this.$http({
          url: "/inventory/add",
          method: "post",
          data: params
        }).then((res) => {
          this.loading = false
          if (res.code === 1) {
            // console.log('res', res)
            sessionStorage.setItem('refresh', true)
            this.$router.push({ name: "departDetail", query: {department: this.$route.query.departmentId, name: this.$route.query.name, status: this.$route.query.status} });
          } else {
            this.$toast.fail(res.msg);
          }
        }).catch(err => {
          console.log(err)
          this.loading = false
          this.$toast.fail('保存失败！');
        })
      }
    },
    save_copy() {
      if (!this.isTrue) {
        return
      }
      this.fileList_url = this.fileList_obj.map(e => e.url)
      // if (this.$route.query.id && (this.replay_status == 0)) {
      //   let len = this.fileList_url.filter(e => this.oldfileList_url.includes(e))
      //   if (len.length === this.fileList_url.length) {
      //     this.$toast.fail('请上传复盘照片');
      //     console.log('aaaaa', this.oldfileList_url, this.fileList_url, len)
      //     return
      //   }
      // }
      let params = {
        department: this.department,
        name: this.name,
        op_name: this.op_name,
        check_no: this.check_no,
        type_main: this.type_main,
        type_sub	: this.type_sub,
        brand: this.brand,
        model: this.model,
        year: this.year,
        room_no: this.room_no,
        user: this.user,
        unused: this.unused,
        is_repair: this.is_repair,
        remark: this.remark,
        images: this.fileList_url,
        linked_id: this.linked_id ,
        status: this.guanlian.linked_name === 'A+级关联' ? 1 : this.guanlian.linked_name === 'A级关联' ? 2 : this.guanlian.linked_name === 'B级关联' ? 3 : '',
        item_no: this.guanlian.item_no,
        enter_no: this.enter_no,
        replace: this.replace
      }
      console.log('params', params)
      if (this.$route.query.id) {
        this.$http({
          url: "/inventory/edit",
          method: "post",
          data: {...params, id: this.$route.query.id}
        }).then((res) => {
          this.loading = false
          if (res.code === 1) {
            this.$toast.success('保存并复制成功！');
            this.check_no = ''
            this.images = []
            this.fileList = []
            this.fileList_url = []
            this.fileList_obj = []
            this.guanlian = {}
            this.glBtn_name = '关联'
            this.linked_id = ''
            this.$route.query.id = ''
          } else {
            this.$toast.fail(res.msg);
          }
        }).catch(err => {
          console.log(err)
          this.loading = false
          this.$toast.fail('保存失败！');
        })
      } else {
        this.$http({
          url: "/inventory/add",
          method: "post",
          data: params
        }).then((res) => {
          this.loading = false
          if (res.code === 1) {
            // console.log('res', res)
            this.$toast.success('保存并复制成功！');
            sessionStorage.setItem('refresh', true)
            this.check_no = ''
            this.images = []
            this.fileList = []
            this.fileList_url = []
            this.fileList_obj = []
            this.guanlian = {}
            this.glBtn_name = '关联'
            this.linked_id = ''
            // this.$router.push({ name: "departDetail", query: {department: this.$route.query.departmentId, name: this.$route.query.name, status: this.$route.query.status} });
          } else {
            this.$toast.fail(res.msg);
          }
        }).catch(err => {
          console.log(err)
          this.loading = false
          this.$toast.fail('保存失败！');
        })
      }
    },

    getEditData() {
      this.$http({
        url: "/inventory/detail/id",
        method: "post",
        params: {id: this.$route.query.id}
      }).then((res) => {
        if (res.code === 1) {
          let data = res.data
          this.department = data.department
          this.enter_no = data.enter_no
          this.name = data.name
          this.op_name = data.op_name
          this.check_no = data.check_no
          this.type_main = data.type_main
          this.type_sub = data.type_sub
          this.brand = data.brand
          this.model = data.model
          this.year = data.year
          this.room_no = data.room_no
          this.user = data.user
          this.unused = String(data.unused)
          this.is_repair = String(data.is_repair)
          this.remark = data.remark
          this.fileList_url = data.images
          this.oldfileList_url = data.images
          this.linked_id = data.linked_id
          this.guanlian = data.item
          this.guanlian.linked_name = data.status === 1 ? 'A+级关联' : data.status === 2 ? 'A级关联' : data.status === 3 ? 'B级关联' : ''
          this.guanlian_remark = data.item.remark
          // this.isDisabled = data.replay_status === 1 ? true : false
          this.guanlian.user = data.item.user
          this.remark_list = data.remark_list
          this.is_replay = data.is_replay
          this.replay_status = data.replay_status

          this.isStatus = this.common.formatQueryDate(this.$route.query.status)
          this.isAA = data.status === 1
          if (this.isAA) {
            this.glBtn_name = '查看'
          } else if (data.status === 2 || data.status === 3) {
            this.glBtn_name = '解除关联'
          }
          // this.isEdit = data.status > 0 && data.status < 4
          this.fileList_obj = this.fileList_url.map(e => {
            return {
              url: e,
              name: e
            }
          })
          this.fileList = this.fileList_url.map(e => {
            return {
              // url: 'http://guyou.bjxiaomai.cn' + e
              url: 'https://image.bjguyou.com' + e
            }
          })
          // console.log('66', this.fileList)
          setTimeout(() => {
            console.log('this.depObj', this.depObj, this.department)
            this.department_name = (this.depObj.find(e => e.id == this.department)).name
            let a = this.typeList.find(e => e.id === this.type_main)
            this.type_name = a ? a.name + '-' + a.children.find(e => e.id === this.type_sub).name: ''
          }, 100)
        } else {
          this.$toast.fail('资产信息查询失败!');
        }
      })
    },
    setType() {
      let a = this.typeList.find(e => e.id === this.type_main)
      this.type_name = a.name + '-' + a.children.find(e => e.id === this.type_sub).name
    },
    setDep() {
      this.department_name = (this.depObj.find(e => e.id === this.department)).name
    },

    edit_click() {
      console.log('this.isAA', this.isAA, this.$route.query.status, this.isEdit, this.isStatus)
      if (this.$route.query.status || this.isAA) {
        console.log('aaaa')
        return
      }
      this.$dialog.confirm({
        title: '温馨提示',
        message: '解除关联后才可编辑',
        confirmButtonText: '⽴即解除'
      })
      .then(() => {
        this.$http({
          url: "/inventory/remove",
          method: "post",
          data: {id: this.linked_id}
        }).then((res) => {
          if (res.code === 1) {
            this.$toast.success('解绑成功！');
            location.reload()
          } else {
            this.$toast.fail(res.msg);
          }
        });
      })
      .catch(() => {
        // on cancel
      });
    },

    // 使用部门change事件
    department_change() {
      if (this.isAA) {
        this.btn_text = '检索关联状态'
      }
    },
    model_change() {
      if (this.isAA) {
        this.btn_text = '检索关联状态'
      }
    },
    // // 检索关联状态按钮事件
    // guanlianAA() {
    //   let params = {
    //     department: this.department,
    //     model: this.model
    //   }
    //   this.$http({
    //     url: "/inventory/match",
    //     method: "post",
    //     data: params
    //   }).then((res) => {
    //     if (res.code === 1) {
    //       if (res.data) {
    //         this.AAsearch_id = res.data.id
    //         this.linked_id = res.data.id
    //         this.guanlian.linked_name ='A+级关联'
    //         this.glBtn_name = '查看'
    //         this.guanlian.item_no = res.data.item_no
    //       } else {
    //         this.glBtn_name = '关联'
    //         this.showAssetsGl = true
    //       }
    //     } else {
    //       this.$toast.fail(res.msg);
    //     }
    //   });
    // },
    // 进来时A+关联那 是查看，非A+ 则是关联

    systemName_click() {
      if (this.isDisabled) {
        return
      } else {
        this.showName = true
      }
    },
    close_systemName(name) {
      this.showName = false
      if (name) {
        this.name = name
      }
    },
    changeToPankui() {
      this.$dialog.confirm({
        title: '温馨提示',
        message: '资产转入盘亏后，将解除原有关联状态，请确认是否转入',
        confirmButtonText: '确定转入',
        confirmButtonColor: '#1989fa',
        cancelButtonText: '再想想',
        cancelButtonColor: '#fc6529'
      })
      .then(() => {
        this.$http({
          url: "/inventory/draw2lose",
          method: "post",
          data: {id: this.$route.query.id}
        }).then((res) => {
          if (res.code === 1) {
            this.$toast.success('转入成功！');
            this.$router.push({name: 'checkProject'})
          } else {
            this.$toast.fail(res.msg);
          }
        });
      })
      .catch(() => {
        // on cancel
      });
    }
  },
};
</script>

<style scoped lang="scss">
.gy_departDetail {
  padding-top: 56px;
  .content_people {
    background-color: #f7f8f9;
    padding: 10px 10px;
    .form_block {
      padding: 2px;
      font-size: 1.5rem;
      border-radius: 8px;
      background-color: #fff;
      color: #000;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      // position: relative;
      .custom-remark {
        // background: #f9f9f9;
        padding: 15px;
        border-radius: 12px;
        margin-bottom: 10px;
      }
    }
  }
  .content {
    background-color: #f7f8f9;
    padding: 10px 10px;
    padding-bottom: 80px;
    position: relative;
     .not_click {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99;
      }
    
    .form_block {
      padding: 2px;
      font-size: 1.5rem;
      border-radius: 8px;
      background-color: #fff;
      color: #000;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      position: relative;
     
    }
    .info {
      padding: 0 15px;
      font-size: 1.5rem;
      border-radius: 8px;
      background-color: #fff;
      color: #000;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      margin-top: 10px;
      .row {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #f5f6f7;
        padding: 20px 0;
        .text {
          margin-left: 3rem;
        }
        .gl_btn {
          width: 90px;
          height: 40px;
        }
      }
      .first_row {
        padding: 10px 0;
        align-items: center;
      }
      .remarks {
        padding-left: 10px;
        .van-cell__value {
          background: #F7F8F9;
          padding: 5px 10px;
          border-radius: 6px;
        }
      }
    }
  }
  .btn_block {
    height: 68px;
    background: #f7f8f9;
    
    position: fixed;
    bottom: 0;
    width: 100%;
    // display: flex;
    .van-button {
      width: 80%;
      margin: 12px auto 0;
      background: #E2EBF2;
      border-color: #E2EBF2;
      color: #BDC8D1;
    }
    .btn_red {
      background-color: red;
      color: #fff;
      width: 43%;
    }
    .btn_true {
      color: #fff;
      background-color: #1989fa;
      border: 1px solid #1989fa;
    }
  }
  .disabled .van-cell__value {
    color: #c8c9cc;
  }
}
</style>