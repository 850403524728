<template>
  <div class="gy_departDetail">
    <van-nav-bar
      :title="$route.query.name || ''"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
      fixed
    >
      <template #right>
        <van-icon name="search" />
      </template>
    </van-nav-bar>
    <div class="mask" v-if="showScreen" @touchmove.prevent>
      <van-nav-bar
        title="搜索盘点资产"
        left-arrow
        @click-left="screen_onClickLeft"
        @click-right="screen_onClickRight"
      >
        <template #right>
          <span class="search">搜索</span>
        </template>
      </van-nav-bar>
      <div class="item-block">
        <span :class="['btn', { active: type === 1 }]" @click="type = 1"
          >盘点编号</span
        >
        <span :class="['btn', { active: type === 2 }]" @click="type = 2"
          >资产编号</span
        >
        <span :class="['btn', { active: type === 3 }]" @click="type = 3"
          >名称</span
        >
        <span :class="['btn', { active: type === 4 }]" @click="type = 4"
          >型号</span
        >
        <span :class="['btn', { active: type === 5 }]" @click="type = 5"
          >品牌</span
        >
      </div>
      <van-field v-model="kw" :placeholder="type | filterType" />
      <div style="height: 100%" @click="showScreen = false"></div>
    </div>

    <!-- 部门解锁 -->
    <div class="mask" v-if="showUnlock" @touchmove.prevent>
      <van-nav-bar
        title="解锁验证"
        left-arrow
        @click-left="unlock_onClickLeft"
        @click-right="unlock_onClickRight"
      >
        <template #right>
          <span class="search">确定</span>
        </template>
      </van-nav-bar>
      <van-field v-model="mobile" placeholder="请输入手机号" />
      <div class="code">
        <span class="text" @click="getCode()">{{
          showTime ? time + "s" : "获取验证码"
        }}</span>
        <van-field v-model="code" placeholder="请输入验证码" />
      </div>
      <div class="tips" v-if="showTips">
        该手机号无效，请重新输入项目对接人手机号
      </div>
      <div style="height: 100%" @click="showUnlock = false"></div>
    </div>

    <div class="content">
      <div class="info">
        <div class="row" @click="showLink = true">
          <span>关联状态</span>
          <span class="text">
            {{ linked_name }}
          </span>
        </div>
        <!-- <div class="row" @click="showType = true">
          <span>资产类别</span>
          <span class="text">
            {{type_name}}
          </span>
        </div> -->
      </div>
      <div class="table" ref="qqq">
        <div class="header">
          <span class="header_left">资产列表</span>
          <span class="header_right">{{ num }}件</span>
        </div>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :immediate-check="false"
          ref="gy_departDetail"
        >
          <div
            v-for="(item, index) in dataList"
            :key="index"
            class="item"
            @click="
              turnAddAssets(
                item.id,
                item.is_reply === 1 && item.replay_status == '0'
              )
            "
          >
            <div class="title">
              <div class="name">
                {{ item.name }}
                <span v-if="item.replay_status == '0'" class="dai">待</span>
                <span v-if="item.is_enter == 1 && !item.replay_time" class="lu"
                  >录</span
                >
              </div>
              <div>
                <span
                  class="circle"
                  :style="{
                    background:
                      item.status < 4 && item.status > 0
                        ? '#27E253'
                        : '#efefef',
                  }"
                  >{{ item.status | filterStatus }}</span
                >
                <van-icon name="arrow" class="icon" />
              </div>
            </div>
            <div class="text">
              <span>盘点编号:{{ item.check_no }}</span>
              <span style="margin-left: 30px"
                >{{ item.type_main }}-{{ item.type_sub }}</span
              >
            </div>
          </div>
        </van-list>
      </div>
    </div>
    <div>
      <div v-if="homePageStatus" class="btn_block" style="width: 100%">
        <van-button round block class="btn2" type="info" @click="dep_unlock()"
          >部门解锁</van-button
        >
      </div>
      <div v-else class="btn_block">
        <van-button round block class="btn1" type="info" @click="sign"
          >验收签字</van-button
        >
        <van-button round block type="info" @click="addPd">添加盘点</van-button>
      </div>
    </div>
    <van-popup v-model="showLink" round position="bottom">
      <van-picker
        show-toolbar
        :columns="linkList"
        @cancel="showLink = false"
        @confirm="onConfirm_link"
      />
    </van-popup>
    <van-popup v-model="showType" round position="bottom" class="assets_popup">
      <van-picker
        show-toolbar
        :lazy-render="false"
        :columns="typeList"
        @cancel="showType = false"
        @confirm="onConfirm_type"
        ref="pickerType"
      />
    </van-popup>
  </div>
</template>
<script>
// import { formatQueryDate } from '../../main'
import { store, mutation } from "@/store";

export default {
  name: "departDetail",
  data() {
    return {
      departmentId: "",
      O: {},
      linkList: [],
      typeList: [],
      status: null,
      type_main: null,
      type_sub: null,
      showLink: false,
      showType: false,
      showScreen: false,
      kw: "",
      num: 0,
      isDone: true,
      // dataList:[],
      linked_name: "全部",
      type_name: "全部",
      showUnlock: false,
      mobile: "",
      code: "",
      showTime: false,
      time: 60,
      showTips: false,
      homePageStatus: false,
      loading: false,
      total: 0,
      finished: false,
      page: 1,
      dataList: [],
      page_size: 50,
      type: 1,
    };
  },

  filters: {
    filterStatus(value) {
      switch (value) {
        case 1:
          return "A+";
        case 2:
          return "A";
        case 3:
          return "B";
      }
    },
    filterType(value) {
      switch (value) {
        case 1:
          return "请输入盘点编号";
        case 2:
          return "请输入资产编号";
        case 3:
          return "请输入名称";
        case 4:
          return "请输入规格型号";
        case 5:
          return "请输入品牌";
      }
    },
  },
  mounted() {
    console.log("mounted", store.departList);
    // console.log('$route.query.status', this.$route.query.status, Boolean(this.$route.query.status))
    // console.log(this.common.formatQueryDate(), this.$route.query.status)
    let a = this.$route.query.status || false;
    // console.log('aaaaaaaaaaaa', a)
    this.homePageStatus = this.common.formatQueryDate(a);
    this.departmentId = this.$route.query.department;
    // 关联状态保存
    if (sessionStorage.getItem("linked_name")) {
      this.linked_name = sessionStorage.getItem("linked_name");
      this.status = sessionStorage.getItem("glStatus");
    }
    if (sessionStorage.getItem("total")) {
      this.num = Number(sessionStorage.getItem("total"));
      this.total = this.num;
    }
    this.getGL();
    this.getFL();
    if (JSON.parse(sessionStorage.getItem("refresh"))) {
      this.dataList = [];
      this.getData();
    } else {
      this.page_size = 50 * store.departList.page;
      this.getData();
      // if(!store.departList.list.length) {
      //   this.getData()
      //   return
      // }
      // this.dataList  =store.departList.list
      //  this.page = store.departList.page
      //  this.status = store.departList.status
      // if(store.departList.list.length === store.departList.total) {
      //   this.finished = true
      // }
      this.$nextTick(() => {
        const top = this.$route.meta.scrollTop || 0;
        this.$refs.qqq.scrollTop = top;
      });
    }
  },
  methods: {
    onClickLeft() {
      // sessionStorage.removeItem('linked_name')
      // sessionStorage.removeItem('glStatus')
      // sessionStorage.removeItem('dataList')
      // sessionStorage.removeItem('total')
      // sessionStorage.removeItem('page')
      mutation.setDepartList({ list: [], total: 0, page: 1, status: null });
      sessionStorage.removeItem("refresh");
      this.$router.push({ name: "checkProject" });
    },

    getData() {
      let params = {
        id: this.departmentId,
        // page: 1,
        status: this.status,
        type_main: this.type_main,
        type_sub: this.type_sub,
        kw: this.kw,
        page: this.page,
        page_size: this.page_size,
      };
      // console.log('66', this.dataList)
      this.$http({
        url: "/project/department",
        method: "post",
        data: params,
      }).then((res) => {
        if (res.code === 1) {
          const dataList = this.dataList.concat(res.data.list);
          console.log("http get dataList", dataList);

          this.total = res.data.total;
          this.num = res.data.total;
          this.loading = false;

          if (dataList.length >= this.total) {
            this.finished = true;
          }
          this.page = this.page + 1;
          this.dataList = dataList;
          mutation.setDepartList({
            list: dataList,
            total: res.data.total,
            page: this.page,
            status: this.status,
          });
          // console.log('res', res)
          // this.num = res.data.total
          // this.dataList = res.data.list
          this.$nextTick(() => {
            this.$refs.qqq.scrollTo(
              0,
              sessionStorage.getItem("scrollToAddAssets")
            );
          });
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },

    onLoad() {
      // if (!sessionStorage.getItem('page')) {
      // console.log('66')
      this.getData();
      // } else {
      // console.log('88')
      // this.dataList = JSON.parse(sessionStorage.getItem('dataList'))
      // this.page = Number(sessionStorage.getItem('page'))
      // this.total = Number(sessionStorage.getItem('total'))
      // this.loading = false;
      // this.$nextTick(() => {
      //   this.$refs.qqq.scrollTo(0, sessionStorage.getItem("scrollToAddAssets"))
      //   sessionStorage.removeItem('page')
      // })
      // }
    },

    getGL() {
      this.$http({
        url: "/project/linked",
        method: "post",
      }).then((res) => {
        if (res.code === 1) {
          // console.log('res', res)
          // this.O = res.data
          this.O = {
            1: "A+",
            2: "A",
            3: "B",
            0: "未关联",
          };
          this.linkList = Object.values(this.O);
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },

    getFL() {
      this.$http({
        url: "/project/type",
        method: "post",
      }).then((res) => {
        if (res.code === 1) {
          // console.log('res', res)
          let list = res.data;
          this.typeList = this.recursionData(list);
          // console.log('888', this.typeList)
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },

    recursionData(list) {
      let arr = list.map((e) => {
        e.text = e.name;
        e.value = e.id;
        if (e.children && e.children.length > 0) {
          this.recursionData(e.children);
        } else if (e.children && e.children.length == 0) {
          e.children = [{ text: "", id: "" }];
        }
        return e;
      });
      // console.log('999', arr)
      return arr;
    },

    onConfirm_link(value) {
      this.status = Object.keys(this.O)[
        Object.values(this.O).findIndex((e) => e === value)
      ];
      this.linked_name = value;
      this.showLink = false;
      this.page = 1;
      this.dataList = [];
      this.loading = true;
      this.finished = false;
      this.getData();
    },

    onConfirm_type() {
      let ref = this.$refs["pickerType"].getValues();
      this.type_main = ref[0].id;
      this.type_sub = ref[1].id;
      this.type_name = ref[0].name + (ref[1].name ? "-" + ref[1].name : "");
      this.showType = false;
      this.page = 1;
      this.getData();
    },

    turnAddAssets(id, reply) {
      sessionStorage.setItem("scrollToAddAssets", this.$refs.qqq.scrollTop);
      if (this.linked_name !== "全部") {
        sessionStorage.setItem("glStatus", this.status);
        sessionStorage.setItem("linked_name", this.linked_name);
      }
      sessionStorage.setItem("dataList", JSON.stringify(this.dataList));
      sessionStorage.setItem("page", this.page);
      sessionStorage.setItem("total", this.total);
      sessionStorage.removeItem("refresh");
      this.$router.push({
        name: "addAssets",
        query: {
          departmentId: this.departmentId,
          id,
          status: this.homePageStatus,
          name: this.$route.query.name,
          reply: reply,
        },
      });
    },

    addPd() {
      this.$router.push({
        name: "addAssets",
        query: {
          departmentId: this.departmentId,
          name: this.$route.query.name,
        },
      });
    },

    onClickRight() {
      this.showScreen = true;
    },

    screen_onClickLeft() {
      this.showScreen = false;
    },

    screen_onClickRight() {
      console.log("kw", this.kw);
      this.showScreen = false;
      sessionStorage.removeItem("refresh");
      this.$router.push({
        name: "searchResult",
        query: { kw: this.kw, departmentId: this.departmentId, type: this.type },
      });
    },

    sign() {
      sessionStorage.removeItem("refresh");
      this.$router.push({
        name: "departCheck",
        query: {
          departmentId: this.departmentId,
          name: this.$route.query.name,
        },
      });
    },

    dep_unlock() {
      this.showUnlock = true;
    },
    unlock_onClickLeft() {
      this.showUnlock = false;
    },
    unlock_onClickRight() {
      let params = {
        mobile: this.mobile,
        event: "unlock",
        captcha: this.code,
        dep: this.departmentId,
      };
      this.$http({
        url: "/sms/check",
        method: "post",
        data: params,
      }).then((res) => {
        if (res.code === 1) {
          // console.log('res', res)
          this.$toast.success("解锁成功！");
          sessionStorage.removeItem("refresh");
          this.$router.push({
            name: "checkProject",
            query: { department: this.departmentId },
          });
        } else {
          this.$toast.fail("解锁失败！");
        }
      });
    },
    getCode() {
      let params = {
        mobile: this.mobile,
        event: "unlock",
        dep: this.departmentId,
      };
      this.$http({
        url: "/sms/send",
        method: "post",
        data: params,
      }).then((res) => {
        if (res.code === 1) {
          this.$toast.success("发送验证码成功！");
          this.showTime = true;
          let codeTime = setInterval(() => {
            if (this.time === 0) {
              clearInterval(codeTime);
              this.showTime = false;
              this.time = 60;
            }
            this.time--;
          }, 1000);
          // console.log('res', res)
        } else {
          this.showTips = true;
          // this.$toast.fail(res.msg);
        }
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    console.log("top", this.$refs.qqq.scrollTop);
    this.$route.meta.scrollTop = this.$refs.qqq.scrollTop;
    next();
  },
};
</script>

<style scoped lang="scss">
.gy_departDetail {
  padding-top: 56px;
  .content {
    background-color: #f7f8f9;
    padding: 10px 10px;
    .info {
      padding: 0 15px;
      color: #fff;
      font-size: 1.5rem;
      border-radius: 8px;
      background-color: #fff;
      color: #000;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      .row {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #f5f6f7;
        padding: 20px 0;
        .text {
          margin-left: 3rem;
        }
      }
    }
    .table {
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 15px 10px 15px 15px;
      margin-top: 10px;
      overflow: scroll;
      height: 600px;
      padding-bottom: 60px;
      .header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #efecec;
        padding-bottom: 15px;
        .header_left {
          font-size: 1.5rem;
          font-weight: 500;
          color: #0085f5;
        }
        .header_right {
          font-size: 1.5rem;
          font-weight: 500;
          color: #f5a317;
        }
      }
      .item {
        padding: 20px 0;
        border-bottom: 1px solid #f5f6f7;
        .title {
          margin-bottom: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .name {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            .dai {
              color: #ff8303;
              font-size: 13px;
              background-color: #f7eede;
              border-radius: 50%;
              display: inline-block;
              width: 19px;
              height: 19px;
              text-align: center;
              margin-left: 8px;
            }
          }
          .circle {
            width: 21px;
            height: 21px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 10px;
            color: #fff;
            line-height: 21px;
            text-align: center;
          }
          .icon {
            // vertical-align: super;
            font-size: 18px;
          }
        }
        .text {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
  .btn_block {
    height: 68px;
    background: #ffffff;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    .van-button {
      width: 42%;
      margin: 12px auto 0;
    }
    .btn1 {
      background-color: #def0ff;
      border-color: #def0ff;
      color: #0085f5;
    }
    .btn2 {
      width: 92%;
    }
  }
}
.tips {
  font-size: 14px;
  color: #eb1801;
  background-color: #fff;
  padding: 0px 0 30px 22px;
}
</style>